import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

const ProjectCard = ({
  id,
  title,
  imageURL,
  externalLink,
  reverse,
  content,
  iconLinks,
}: {
  id: string;
  title: string;
  imageURL?: string;
  externalLink?: string;
  reverse?: boolean;
  content: string;
  iconLinks?: string[];
}) => {
  const { t } = useI18next();
  return (
    <div id={id} key={id} className="mt-[5.625rem] lg:mt-[8.75rem]">
      <div
        className={`card flex flex-col lg:flex-row${
          reverse ? '-reverse' : ''
        } justify-between items-stretch gap-[1.875rem] lg:gap-[3.75rem]`}
      >
        <div className="flex flex-col justify-between basis-1/2">
          <h2 className="mb-8 lg:mb-12">{title}</h2>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: content }}
            className="mt-[3.75rem] text-justify career-content"
          />
          {iconLinks && iconLinks.length > 0 && (
            <div className="flex flex-wrap justify-center items-center">
              {iconLinks.map((iconLink, index) => (
                <img
                  key={iconLink}
                  src={iconLink}
                  alt={`Icon ${index + 1}`}
                  className="max-h-[16rem] m-[2rem]"
                />
              ))}
            </div>
          )}
          {externalLink && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={externalLink}
              className="text-white"
            >
              {t('Read more')}
            </a>
          )}
        </div>
        <hr className="lg:vertical lg:h-auto opacity-40" />
        <div className="lg:basis-1/2 flex justify-center items-center overflow-hidden relative">
          {imageURL && (
            <img
              className="object-contain w-full h-auto rounded-xl"
              src={imageURL}
              alt=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
