import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import Base from '../components/base';
import SEO from '../components/seo';
import { formatRawProject } from '../utilities';
import Sides from '../components/ranges/sides';
import ProjectCard from '../components/projects/projectCard';

const Projects = ({
  location,
  data: { projects: rawProjects },
}: PageProps<Queries.ProjectsQuery>) => {
  const { t } = useI18next();
  const projects = rawProjects.nodes.map(formatRawProject);
  return (
    <Base location={location}>
      <div className="pt-[3.5rem] relative lg:rounded-lg overflow-hidden">
        <video
          autoPlay
          loop
          muted
          playsInline
          className=" object-cover w-[100%] h-[100%] inset-0 absolute -z-1"
        >
          <source src="/videos/background.webm" type="video/webm; codecs=vp9" />
          <source src="/videos/background.mp4" type="video/mp4; codecs=hevc" />
          <source src="/videos/background.m4v" type="video/mp4" />
          <Trans>Your browser does not support the video tag.</Trans>
        </video>
        <div className="absolute inset-0 bg-gradient-to-r from-black to-transparent space-y-4 h-[100%] w-[100%]" />
        <div className="lg:rounded-lg">
          <Sides>
            <div className="z-30 py-[5.625rem] lg:py-[8.75rem] text-white">
              <h2 className="mb-[3.75rem] ">{t('Projects title')}</h2>
            </div>
          </Sides>
        </div>
      </div>
      <div className="bg-cover bg-black lg:rounded-lg">
        <Sides>
          <div className="py-[5.625rem] lg:py-[8.75rem]">
            {projects.length === 0 && (
              <div>
                <h3>
                  <Trans>No projects found at the moment</Trans>
                </h3>
                <br />
                <br />
                <h3>
                  <Trans>Check back soon!</Trans>
                </h3>
              </div>
            )}
            <div className="pt-[1.25rem] pb-[5.625rem] ">
              {projects.map(
                ({ title, externalLink, content, image, iconLinks }, index) => (
                  <ProjectCard
                    key={title}
                    id={title}
                    title={title}
                    imageURL={image?.src}
                    externalLink={externalLink}
                    content={content}
                    reverse={index % 2 !== 0}
                    iconLinks={iconLinks}
                  />
                )
              )}
            </div>
          </div>
        </Sides>
      </div>
    </Base>
  );
};

export default Projects;

export const pageQuery = graphql`
  query Projects($language: String!) {
    projects: allMarkdownRemark(
      filter: {
        fields: { sourceName: { eq: "projects" } }
        frontmatter: { language: { eq: $language } }
      }
    ) {
      nodes {
        html
        frontmatter {
          title
          externalLink
          thumbnail {
            publicURL
          }
          iconLinks {
            publicURL
          }
          alttext
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="CR14 Projects" />;
